import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import './i18nextConf';
import App from './App';
import Loading from './components/atoms/Loading';
import theme from './theme';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import frLocale from 'date-fns/locale/fr';
import { ConfirmProvider } from 'material-ui-confirm';
import packageJson from '../package.json';

import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import WithMaintenance from './components/maintenance/withMaintenance';
import store from './store';
import axios from 'axios';
import UpdateAppReminder from './components/UpdateAppReminder';
import { isMobileVersionCompatible } from '@operatech1/mobile-utils';
import { datadogRum } from '@datadog/browser-rum';
import { SnackbarProvider } from 'notistack';

if (
  process.env.NODE_ENV === 'production' &&
  process.env.REACT_APP_ENVIRONMENT === 'production'
) {
  datadogRum.init({
    applicationId: '8f5bdcf1-9ee9-4cf9-be00-000bad94f706',
    clientToken: 'pub28169ef817502878d1a09d8bb9eeb7ea',
    site: 'datadoghq.eu',
    service: 'etiq',
    env: 'production',
    version: !!packageJson?.version ? packageJson?.version : '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowFallbackToLocalStorage: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      'https://api.etiq.com/api/',
      /https:\/\/.*\.api\.etiq\.com\/api\//,
      (url) => url.startsWith('https://api.etiq.com/api/'),
    ],
  });
}
Sentry.init({
  dsn: 'https://244ed595af944a9cbb872260758ea867@o1115582.ingest.sentry.io/4504248231329792',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled:
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_ENVIRONMENT === 'production',
});

const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={frLocale}
      >
        <Provider store={store}>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <WithMaintenance>
                  <CssBaseline />
                  <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    autoHideDuration={3000}
                  >
                    <BrowserRouter>
                      <ConfirmProvider>
                        <Suspense fallback={<Loading />}>
                          <App />
                        </Suspense>
                      </ConfirmProvider>
                    </BrowserRouter>
                  </SnackbarProvider>
                </WithMaintenance>
              </ThemeProvider>
            </StyledEngineProvider>
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

const renderUpdateAppReminder = () =>
  ReactDOM.render(
    <React.StrictMode>
      <UpdateAppReminder />
    </React.StrictMode>,
    document.getElementById('root'),
  );

let persistor = persistStore(store);

if (
  process.env.REACT_APP_IS_MOBILE_APP === 'true' &&
  !!process.env.REACT_APP_MOBILE_APP_VERSION_BUCKET_URL
) {
  axios
    .get(process.env.REACT_APP_MOBILE_APP_VERSION_BUCKET_URL, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
    .then(({ data }) => {
      if (
        !isMobileVersionCompatible(
          packageJson?.version,
          data?.version,
        )
      ) {
        renderUpdateAppReminder();
      } else {
        renderApp();
      }
    })
    .catch((e) => renderApp());
} else {
  renderApp();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
